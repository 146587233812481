a:hover{
    color: grey!important;
}

.card-party {
    padding-top: 20vh;
}

/*Animate titles*/
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
.css-typing p {
    /*border-right: .05em solid orange;*/
    font-family: "Caveat", cursive;
    font-optical-sizing: auto;
    /*font-weight: <weight>;*/
    /*font-style: normal;*/
    font-size: 35px;
    white-space: nowrap;
    overflow: hidden;
    width: 375px;
}

.css-typing p:nth-child(1) {
    /*width: 100px;*/
    -webkit-animation: type 2s steps(40, end);
    animation: type 2s steps(40, end);
    -o-animation: type 2s steps(40, end);
    -moz-animation: type 2s steps(40, end);
    -moz-animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.css-typing p:nth-child(2) {
    /*width: 100px;*/
    opacity: 0;
    -webkit-animation: type3 2s steps(40, end);
    animation: type3 2s steps(40, end);
    -webkit-animation-delay: 2s;
    -moz-animation: type 2s steps(40, end);
    -moz-animation-fill-mode: forwards;
    -moz-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.css-typing p:nth-child(3) {
    /*width: 100px;*/
    opacity: 0;
    -webkit-animation: type3 2.5s steps(60, end);
    animation: type3 2.5s steps(60, end);
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -moz-animation: type 2.5s steps(60, end);
    -moz-animation-fill-mode: forwards;
    -moz-animation-delay: 4s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.css-typing p:nth-child(4) {
    /*width: 100px;*/
    opacity: 0;

    animation: type3 2.5s steps(60, end);
    animation-delay: 6s;
    animation-fill-mode: forwards;

    /*-webkit-animation: type3 2.5s steps(60, end);*/

    -webkit-animation-name: type3;
    -webkit-animation-duration: 2.5s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function:  steps(60, end);
    /*-webkit-animation-fill-mode: forwards;*/

    -webkit-animation-delay: 6s;
    -webkit-animation-fill-mode: forwards;

    -moz-animation: type 2.5s steps(60, end);
    -moz-animation-delay: 6s;
    -moz-animation-fill-mode: forwards;

    -o-animation: type 2.5s steps(60, end);
    -o-animation-delay: 6s;
    -o-animation-fill-mode: forwards;
}

.css-typing p:nth-child(5) {
    /*width: 100px;*/
    opacity: 0;
    -moz-animation: type3 2.5s steps(60, end);
    -webkit-animation: type3 2.5s steps(60, end);
    animation: type3 2.5s steps(60, end);
    -webkit-animation-delay: 8s;
    -moz-animation-delay: 8s;
    animation-delay: 8s;
    -moz-animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
/*.css-typing p:nth-child(3) {*/
/*    !*width: 100px;*!*/
/*    opacity: 0;*/
/*    -webkit-animation: type3 2s steps(20, end), blink .2s step-end infinite alternate;*/
/*    animation: type3 2s steps(20, end), blink .2s step-end infinite alternate;*/
/*    -webkit-animation-delay: 2s;*/
/*    animation-delay: 2s;*/
/*    -webkit-animation-fill-mode: forwards;*/
/*    animation-fill-mode: forwards;*/
/*}*/

@keyframes type {
    0% {
        width: 0;
    }
    99.9% {
        /*border-right: .15em solid #fff;*/
    }
    100% {
        border: none;
    }
}

@-webkit-keyframes type {
    0% {
        width: 0;
    }
    99.9% {
        /*border-right: .15em solid orange;*/
    }
    100% {
        border: none;
    }
}

@keyframes type2 {
    0% {
        width: 0;
    }
    1% {
        opacity: 1;
    }
    99.9% {
        border-right: .15em solid #fff;
    }
    100% {
        opacity: 1;
        border: none;
    }
}

@-webkit-keyframes type2 {
    0% {
        width: 0;
    }
    1% {
        opacity: 1;
    }
    99.9% {
        border-right: .15em solid #fff;
    }
    100% {
        opacity: 1;
        border: none;
    }
}

@keyframes type3 {
    0% {
        width: 0;
    }
    1% {
        opacity: 1;
    }
    10%{
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes type3 {
    0% {
        width: 0;
    }
    1% {
        opacity: 1;
    }
    10%{
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes type3 {
    0% {
        width: 0;
    }
    1% {
        opacity: 1;
    }
    10%{
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes type3 {
    0% {
        width: 0;
    }
    1% {
        opacity: 1;
    }
    10%{
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes type3 {
    0% {
        width: 0;
    }
    1% {
        opacity: 1;
    }
    10%{
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}
@-webkit-keyframes blink {
    50% {
        border-color: tranparent;
    }
}

@media (min-width:1025px) {
    .css-typing p {
        font-size: 75px;
    }
}
@media (min-width:1281px) {
    .css-typing p {
        font-size: 75px;
        width: 45vw;
    }
}

@media (min-width: 1200px)  {
    .css-typing p {
        font-size: 65px;
        width: 45vw;
    }
}
